//@ts-nocheck
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'

import { DropdownContainer, DropdownInput, DropdownMenu, DropdownMenuEl, DropdownToggle } from './style'

interface DropdownProps {
  options: []
  onChange: (option: string) => void
  defaultOption?: string
  currentValue?: string
  isDetailPageSelectedInDropdown?: boolean
  setIframeIntegrationUrl: () => void
}

const getOptionName = (selectedOption, options, isDetailPageSelectedInDropdown) => {
  if (selectedOption) {
    return options?.find((option) => option.uri === selectedOption)?.page_id === 'detail_page' &&
      isDetailPageSelectedInDropdown
      ? 'Детальные страницы'
      : selectedOption
  }

  return options.length === 0 ? 'Выбрать сайт' : 'Выбор страницы'
}

export const Dropdown: React.FC<DropdownProps> = ({
  options,
  onChange,
  defaultOption,
  currentValue,
  isSearch,
  isDetailPageSelectedInDropdown,
  setIframeIntegrationUrl
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(defaultOption)
  const [inputValue, setInputValue] = useState('')
  const [hasSelection, setHasSelection] = useState(!!defaultOption)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const [filteredOptions, setFilteredOptions] = useState(options)

  const isSingleOption = options.length <= 1

  const toggleDropdown = () => {
    if (isSingleOption) return

    setIsOpen(!isOpen)
  }

  const handleOptionClick = (option: any) => {
    setIframeIntegrationUrl()
    if (option?.page_id && option?.page_id === 'detail_page' && isDetailPageSelectedInDropdown) {
      setSelectedOption('Детальная страница')
    } else {
      setSelectedOption(option?.host ?? convertPageNameByHash(option?.uri))
    }

    onChange(option)
    setHasSelection(true)
    toggleDropdown() // закрываем выпадающий список после выбора опции
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false) // закрываем выпадающий список при клике вне его области
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (options.length) {
      setSelectedOption(options[0]?.host ?? convertPageNameByHash(options[0]?.uri))
      onChange(options[0])
      setFilteredOptions(options)
    }
  }, [options])

  useEffect(() => {
    // Обновляем выбранную опцию при изменении defaultOption или currentValue
    if (defaultOption && defaultOption !== selectedOption) {
      setSelectedOption(defaultOption)
      onChange(defaultOption)
      setHasSelection(true)
    }
    if (currentValue && currentValue?.uri !== selectedOption) {
      setSelectedOption(convertPageNameByHash(currentValue.uri))
      onChange(currentValue)
      setHasSelection(true)
    }

    const optionName = getOptionName(selectedOption, options, isDetailPageSelectedInDropdown)
    setInputValue(optionName)
  }, [defaultOption, currentValue, onChange, selectedOption])

  const convertPageNameByHash = (name: string) => {
    return name === '/' ? 'Главная' : name
  }

  const updateInputValue = (value: string) => {
    let val = value
    if (value.match(/^Г(л(а|ав)?(н(a|ая)?)?)?$/i) || value.includes('Главна')) {
      val = '/'
    }
    const filteredOptions = options.filter((option) => option.uri.includes(val) || option.page_id.includes(val))
    setIsOpen(true)
    setInputValue(value)
    setFilteredOptions(filteredOptions)
  }

  return (
    <DropdownContainer ref={dropdownRef}>
      <DropdownToggle
        isActive={isOpen}
        hasSelection={hasSelection}
        onClick={toggleDropdown}
        isSingleOption={isSingleOption}
      >
        {isSearch ? (
          <DropdownInput
            type="text"
            value={inputValue}
            onChange={(e) => {
              const newValue = e.target.value
              updateInputValue(newValue)
            }}
          />
        ) : (
          <span>{options.length === 0 ? 'Выбрать сайт' : selectedOption || 'Выбор страницы'}</span>
        )}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          className="dropdown-icon"
        >
          <path d="M7 10L12 15" stroke="#808080" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M12 15L17 10" stroke="#808080" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </DropdownToggle>
      {isOpen && (
        <DropdownMenu>
          {filteredOptions.map((option, index) => (
            <DropdownMenuEl key={index} onClick={() => handleOptionClick(option)}>
              {option?.page_id && option?.page_id === 'detail_page'
                ? 'Детальные страницы'
                : option?.host ?? convertPageNameByHash(option?.uri)}
            </DropdownMenuEl>
          ))}
        </DropdownMenu>
      )}
    </DropdownContainer>
  )
}
